@use '@/styles/utils/mixins.scss' as *;

.ourProgramsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;

  @include tab() {
    grid-template-columns: repeat(1, 1fr);
    gap: 1.25rem;
  }

  .ourProgramsContentContainer {
    display: flex;
    gap: 1.25rem;
    flex-direction: column;

    @include tab() {
      gap: 3.75rem;
    }
  }

  .ourProgramsList {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    overflow-y: auto;
    max-height: 52.5rem;
    grid-column: span 2;
    width: 100%;
    @include tab() {
      overflow-y: visible;
      padding-top: 0;
      max-height: 100%;
      grid-column: span 1;
    }
  }

  .ourProgramsCard {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    transition: all 0.2s ease-in-out;

    .ourProgramsCardBody {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
    }

    .description {
      color: $neutral08;
    }

    .linkText {
      color: $primary;
      font-weight: 500;
      font-size: 1rem;
    }

    .imageContainer {
      position: relative;
      overflow: hidden;
      border-radius: 1.25rem;
      width: 100%;
      height: 18.75rem;

      .imageWrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
